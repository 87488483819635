<template lang="pug">
//- Command(:filter-function="filterFnc")
//-   CommandInput(placeholder="Type a command or search..." v-model='input')
//-   CommandList(:hide-when-detached="false", :force-mount="true")
//-     CommandEmpty No results found.
//-     CommandItem(v-for='(data) in options' :key='data.value' :value='data.value' @select="gotoDetail(data.value)" ) {{ data.label }}

div(class='min-w-[400px] divide-y-2')
  CommonInput.p-4(
    v-model='input',
    name='search_page',
    placeholder="Search...",
    label=''
    :required="false"
  )
  
  div.result.w-full(class='max-h-[300px] pb-6 overflow-auto')

    .flex.items-center.justify-center.h-full.w-full.text-sm.mt-4(v-if='isLoading') Loading ...
    div.mt-4(v-else-if="options.length > 0")
      .p-2.w-full.text-sm.select-none(
        class='cursor-pointer hover:bg-slate-100' 
        v-for='(data) in options' 
        :key='data.value' 
        :value='data.value' 
        @click="gotoDetail(data.value)"
      ) {{ data.label }}
    .flex.items-center.justify-center.h-full.w-full.text-sm.mt-4(v-else) No results found.
    
  

</template>

<script setup lang="ts">
import { getFuzzySearch } from '@/apis/page'
import { useToast } from '../ui/toast'

const { toast } = useToast()

const input = ref('')
const inputDebounced = refDebounced(input, 1000)

const {
  refetch,
  result,
  onResult,
  loading: isLoading,
} = getFuzzySearch(inputDebounced.value)

const searchResults = ref<IPage[]>([])

const options = computed(() => {
  if (searchResults.value.length > 0) {
    return searchResults.value.map((result) => ({
      value: result.id,
      label: result.attributes.Title,
    }))
  }
  return []
})

onResult(() => {
  searchResults.value = result.value?.search.pages?.data || []
})

onMounted(() => {
  refetch()
})

function filterFnc(lists: typeof options.value, search: string) {
  if (lists.length > 0) {
    console.log({ lists: lists, search })
    return options.value.filter((i) =>
      i.label.toLowerCase().includes(search.toLowerCase()),
    )
  }
  return []
}

watch(inputDebounced, (keyword: string) => refetch({ keyword }))
watch(result, ({ search }) => {})

function gotoDetail(id: string | number) {
  navigateTo({
    name: 'page-slug',
    params: {
      slug: id,
    },
  })
}
</script>

<style scoped></style>
